import React, { useState } from 'react';

function Projects() {
    const projects = [
      {
          'id': 1,
          'project_photo': 'project_turquiz.jpg',
          'project_title': 'TurQuiz - Flask Quiz App with PostgreSQL',
          'project_description': 'Turquiz is a Flask-based web application designed to create and complete quizzes. TurQuiz uses PostgreSQL for data and user storage.',
          'project_source': 'https://github.com/kodtld/TurQuiz',
          'project_demo': 'https://turquiz.fly.dev/',
          'project_tags': 'Python, Flask, SQL, FullStack'
      },
      {
          'id': 2,
          'project_photo': 'project_markov.jpg',
          'project_title': 'Markov Chain Sentence Generator',
          'project_description': 'An application that generates realistic fake sentences based on learning data. Stores information in a Trie and uses Markov Chains to generate sentences.',
          'project_source': 'https://github.com/kodtld/Markov-s-Letters',
          'project_tags': 'Python, Algorithms, Machine Learning, Data Structures'
      },
      {
          'id': 3,
          'project_photo': 'project_universe.jpg',
          'project_title': 'Upvoted Universe - Automated Content Creator & Uploader',
          'project_description': 'Upvoted Universe is a program that creates and uploads Instagram videos, where text-to-speech clips of Reddit posts are pasted over background clips.',
          'project_source': 'https://github.com/kodtld/Upvoted_Universe',
          'project_demo': 'https://www.instagram.com/upvoteduniverse/',
          'project_tags': 'Python, Selenium, Praw, gTTS, MoviePy'
      },

      {
        'id': 4,
      'project_photo': 'project_democracy.jpg',
        'project_title': 'Interactive Branching Animation/Game',
        'project_description': 'An interactive branching animation JavaScript game about the state of democracy and free speech online, used for educational purposes.',
        'project_demo': 'https://kodtld.github.io/Democracy_IA_Game/',
        'project_tags': 'JavaScript, HTML, CSS'
      },

      {
          'id': 5,
          'project_photo': 'project_europeday.jpg',
          'project_title': 'The Finnish Europe Day - Website',
          'project_description': 'Wordpress site for the Finnish Europe Day. Europe Day is an annual observance that celebrates peace and unity in Europe (est. 1950).',
          'project_demo': 'https://www.eurooppapaiva.fi',
          'project_tags': 'WordPress, Freelance'
      },
      {
          'id': 6,
          'project_photo': 'project_europefinland.jpg',
          'project_title': 'Organization website - European Finland',
          'project_description': 'Wordpress site for the European Finland organization. Features 20+ pages. Done in cooperation with the designer.',
          'project_demo': 'https://www.eurooppalainensuomi.fi',
          'project_tags': 'WordPress, Freelance'
      },
      {
          'id': 7,
          'project_photo': 'project_kt.jpeg',
          'project_title': 'Design and website for KT-Tiemerkinnät',
          'project_description': 'Website design and implementation for a road marking company. The current site has been updated by other entities due to SEO reasons.',
          'project_demo': 'https://www.kt-tiemerkinnat.fi/',
          'project_tags': 'WordPress, Freelance'
      },
      {
          'id': 8,
          'project_photo': 'project_portfolio.png',
          'project_title': 'Konsta Salmi - Portfolio site (this site)',
          'project_description': "The site you're currently visiting, was handbuilt by me. It uses a React frontend which fetches projects from Flask backend.",
          'project_demo': '#',
          'project_tags': 'JavaScript, React, Flask, SQL'
      },
      {
          'id': 9,
          'project_photo': 'project_bibtext.jpg',
          'project_title': 'BibTeX Generator - An Agile Group Project',
          'project_description': 'A Scrum group project, where we built a python-based BibTeX generator with comprehensive tests and continuous integration.',
          'project_source': 'https://github.com/kodtld/BibTex_GroupProject',
          'project_tags': 'Python, Scrum, CI, Robot, SQL'
      },
      {
          'id': 10,
          'project_photo': 'project_fullstack.jpg',
          'project_title': 'FullStack Open - Modern Web Development',
          'project_description': 'Completed the FullStack Open course: Learn React, Redux, Node.js, MongoDB, GraphQL and TypeScript in one go!',
          'project_source': 'https://github.com/kodtld/FullStackOpen',
          'project_tags': 'FullStack, JavaScript, React, Redux, Node.JS, MongoDB, SQL, TypeScript'
    }
  ]
  
  const [filterTag, setFilterTag] = useState('All');

  return (
    <div>
      <div className='filter_section'>
        <p className='filter_explanation'>Filter projects</p>
        <div className="filter-links">
          <button className={`filter-button${filterTag === 'All' ? ' active' : ''}`} onClick={() => setFilterTag('All')}>All</button>
          <button className={`filter-button${filterTag === 'Python' ? ' active' : ''}`} onClick={() => setFilterTag('Python')}>Python</button>
          <button className={`filter-button${filterTag === 'JavaScript' ? ' active' : ''}`} onClick={() => setFilterTag('JavaScript')}>JavaScript</button>
          <button className={`filter-button${filterTag === 'SQL' ? ' active' : ''}`} onClick={() => setFilterTag('SQL')}>SQL</button>
          <button className={`filter-button${filterTag === 'WordPress' ? ' active' : ''}`} onClick={() => setFilterTag('WordPress')}>WordPress</button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {projects
            .filter(project => filterTag === 'All' || project.project_tags.includes(filterTag))
            .map(project => (
              <div key={project.id} className="project_card">
                <img src={`/images/${project.project_photo}`} alt={`${project.project_photo}`} className="project_photo" />
                <p className="project_title">{project.project_title}</p>
                <p className="project_description">{project.project_description}</p>
                {project.project_source && (
                  <a href={project.project_source} target="_blank" rel="noopener noreferrer" className="project_links">
                    Source code
                  </a>
                )}
                {project.project_demo && (
                  <a href={project.project_demo} target="_blank" rel="noopener noreferrer" className="project_links">
                    Live demo
                  </a>
                )}
                <div className="project_tags">
                  {project.project_tags.split(',').map(tag => (
                    <p key={tag} className="project_tag">
                      {tag.trim()}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Projects;
