import React, { useEffect} from 'react';
import nus_logo from './nus_logo.png'
import hel_logo from './hel_logo.png'
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faPython } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import {faDatabase, faFile} from '@fortawesome/free-solid-svg-icons'
import { faUserGear } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Particle from './components/particle';
import Projects from './components/projects';

function App() {

  useEffect(() => {
    document.title = 'Konsta Salmi';
  }, []); // The empty array ensures this effect runs only once, when the component mounts

  
  // const [projects, setProjects] = useState([]);
  

  // useEffect(() => {
  //   fetch('http://localhost:5000/projects')
  //     .then(response => response.json())
  //     .then(data => setProjects(data.projects))
  //     .catch(error => console.error('Error fetching projects:', error));
  // }, []);
  
  return (
    <div>
      <nav>
          <input type="checkbox" id="nav" class="hidden"/>
          <label for="nav" class="nav-open"><i></i><i></i><i></i></label>
          
          <div class="nav-container">
            <ul>
              <li><a href="#project_section">Projects</a></li>
              <li><a href="#skill_section">Skills</a></li>
              <li><a href="#about_section">About Me</a></li>
            </ul>
          </div>
      </nav>
      <div id="particles-js">
        <Particle/>
        <div id='hero_content'>   
          <h1 class='hero_title'>Konsta Salmi</h1>
          <h2 class='hero_desc'>Full Stack problem solver</h2>
        </div>
        <a href="https://github.com/kodtld" target='new' id="github"><FontAwesomeIcon icon={faGithub} style={{color: "#ffffff",}} /></a>
      </div>



      <div id='project_section'>
        <h1 id='section_title'>01 - Projects</h1>
        <div class="explanation_container">
          <p id='ex_title'>Development Projects</p>
          <p id='ex_main'>Here, you'll find a showcase of my projects with a primary focus on Python, JavaScript, and WordPress. The section consists of freelance work, as well as personal-/school projects.</p>
          <p id="ex_low">I take pride in my adaptability and can-do attitude. As showcased by the projects, I’m glad to challenge myself with a diverse array of technical tasks.</p>

        </div>

        <Projects/>
        
        </div>

      
      
      <div id='skill_section'>
        <h1 id='section_title'>02 - Skills</h1>
        <div class="explanation_container">
          <p id='ex_title'>Technical skills</p>
          <p id='ex_main'>The skills listed in this section represent my proficiency in specific tools, technologies, and methodologies that I have acquired through hands-on experience and academic learning.</p>
          <p id="ex_low">While this section solely focuses on technical skills, my soft skills are still an important part of my professional skillset.</p>

        </div>
        <div class="container">
          <div class="skill_row">      
            <div class="card">
              <div class="card-header">
                <FontAwesomeIcon icon={faPython} style={{color: "#8BF9CF",}} class='skill_icon'/>
                <h3>Backend Development</h3>
              </div>
              <div class="card-body">
                <ul>
                  <li class="a_skill">Python</li>
                  <li class="a_skill">Flask</li>
                  <li class="a_skill">Django</li>
                  <li class="a_skill">Node.js</li>
                </ul>
              </div>
            </div>
      
            <div class="card">
              <div class="card-header">
              <FontAwesomeIcon icon={faReact} style={{color: "#8BF9CF",}} class='skill_icon' />
                <h3>Frontend Development</h3>
              </div>
              <div class="card-body">
              <ul>
                  <li class="a_skill">React</li>
                  <li class="a_skill">JavaScript</li>
                  <li class="a_skill">CSS</li>
                  <li class="a_skill">HTML</li>
                  <li class="a_skill">WordPress</li>
                </ul>
              </div>
            </div>
      
            <div class="card">
              <div class="card-header">
                <FontAwesomeIcon icon={faDatabase} style={{color: "#8BF9CF",}} class='skill_icon'/>
                <h3>Data <br/>Management</h3>
              </div>
              <div class="card-body">
                <ul>
                  <li class="a_skill">PostgreSQL</li>
                  <li class="a_skill">SQLite</li>
                  <li class="a_skill">MongoDB</li>
                  <li class="a_skill">R</li>
                </ul>
              </div>
            </div>
      
            <div class="card">
              <div class="card-header">
                <FontAwesomeIcon icon={faUserGear} style={{color: "#8BF9CF",}} class='skill_icon'/>
                <h3>Tools and Infrastructure</h3>
              </div>
              <div class="card-body">
                <ul>
                  <li class="a_skill">Pytest</li>
                  <li class="a_skill">Robot test</li>
                  <li class="a_skill">GitHub Actions</li>
                  <li class="a_skill">Git/GitHub</li>
                  <li class="a_skill">Linux</li>
                  <li class="a_skill">Bash</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      
      <div id='about_section'>
        <h1 id='section_title'>03 - About Me</h1>
        <div class="explanation_container">
          <p id='ex_title'>Who am I?</p>
          <p id='ex_main'>I am a developer/computer science student with a strong interest in both the technical and creative aspects of the field. As a curious person, I often explore independent projects, allowing me to apply what I learn in a practical context. My experience in sales and freelance work has not only enhanced my technical skills but also developed my ability to interact effectively with clients and colleagues. 
          <br/><br/>Alongside my major in Computer Science, I have a minor in Philosophy, which has helped me with my critical thinking and communication skills. My diverse background helps me quickly adapt to new technologies and effectively implement them in various scenarios. Balancing technical knowledge with a creative approach is what I strive for in my professional development.
          </p>
          <p id="ex_low">Maybe our interests align? I'd love to hear from you:</p>

        </div>
        <div class="contact_container">
        <FontAwesomeIcon icon={faEnvelope} class='contact_icon'/> 
        <p id='ex_con'>kodu@live.fi</p>
        <FontAwesomeIcon icon={faPhone} class='contact_icon'/>        
        <p id='ex_con'>+358 449878289</p>
        <FontAwesomeIcon icon={faFile} class='contact_icon'/>    
        <a href="/CV_Konsta_Salmi.pdf" download="CV_Konsta_Salmi.pdf" id='ex_con'>PDF Resume</a>
        </div>
        {/* <div class="contact_container">
        <FontAwesomeIcon icon={faPhone} class='contact_icon'/>        
        <p id='ex_con'>+65 81568631</p>
        </div>
        <div class="contact_container">
        <FontAwesomeIcon icon={faFile} class='contact_icon'/>    
        <a href='#' id='ex_con'>PDF Resume</a>
        </div> */}
        <div class="education_container">
        <p id='edu_bachelor_title'>Bachelor's degree</p>
        <div class="school_card">
          <img src={hel_logo} alt="hel_logo" id='hel_logo'/>
          <p id='sc_info'>Major: Computer Science<br/>
                          University of Helsinki<br/>
                          Gpa: 4.3 / 5</p>
        </div>

        <div class="lil_school_card">
            <img src={nus_logo} alt="nus_logo" id='nus_logo'/>
            <p id='sc_info'>Exchange/Minor: Philosophy<br/>
                          National University of Singapore<br/></p>
        </div>


        <p id='edu_masters_title'>Master's degree</p>
        <div class="master_school_card">
          <img src={hel_logo} alt="hel_logo" id='hel_logo'/>
          <p id='sc_info'>Estimated start: 2024<br/>
                          Major: Computer Science<br/>
                          University of Helsinki<br/>
                          </p>
        </div>

        {/* <div class="master_school_card">
            <img src={nus_logo} alt="nus_logo" id='nus_logo'/>
            <p id='sc_info'>Minor: Philosophy<br/>
                          National University of Singapore<br/>
                          Gpa: TBD / 5</p>
        </div> */}

        </div>
      </div>
  
    </div>
  </div>

  );
}

export default App;
